import React, { Component } from "react";

import {
  CircularProgress,
  Typography
} from '@material-ui/core';

import Button from '@material-ui/core/Button';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Label,
  Input,
  Col,
  FormGroup,
} from "reactstrap";
import moment from 'moment';

import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import TableTheme from '../../../components/Table/Theme';

import balanceEnquiryApi from "../../../api/BalanceEnquiry";

class ViewLocationDialog extends Component {
  constructor(props) {
    super(props);

    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);
    let item = props.item ? props.item : null;
    let total = 0;
    let data = [];
    if (item && item.order_detail_histories && item.order_detail_histories.length > 0) {
      total = item.order_detail_histories.length;
      data = item.order_detail_histories;
      //data.sort((a, b) => a.updated_at - b.updated_at);
    }
    this.state = {
      modal: modal,
      isLoading: false,
      item: item,
      total: total,
      data: data
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      if (nextProps.modal) {
        if (nextProps.item) {
          let item = nextProps.item ? nextProps.item : null;
          this.setState({
            item: item
          }, () => this.getData())
        }
      }
      this.setState({
        modal: nextProps.modal
      });
    }
  }

  getData = async () => {
    let item = this.state.item;

    if (item) {
      await balanceEnquiryApi.getDetails(item.id).then(data => {
        if(data.data) {
          let item = data.data;
          let total = 0;
          let resData = [];
          console.log(item);
          if (item && item.order_detail_histories && item.order_detail_histories.length > 0) {
            total = item.order_detail_histories.length;
            resData = item.order_detail_histories;
            resData.sort((a, b) => {
              var dateA = new Date(a.updated_at);
              var dateB = new Date(b.updated_at);
              return dateB - dateA;
            });
          }
          this.setState({
            total: total,
            data: resData
          })
        }
      }).catch(error => console.log(error));
    }
  }

  handleClose = () => {
    this.setState({
      modal: false
    }, () => {
      if (this.props.onClose) {
        this.props.onClose(true);
      }
    });
  }

  render() {

    const columns = [
      {
        name: "updated_at",
        label: "Updated",
        options: {
          customBodyRender: value => moment(value).format('DD-MM-YYYY HH:mm:ss')
        }
      },
      {
        name: "location",
        label: "Location"
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: 999999,
      rowsPerPageOptions: [999999],
      serverSide: false,
      sort: false,
      download: false,
      print: false,
      search: false,
      viewColumns: false,
      filter: false,
      pagination: false
    }


    return (
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="md" zIndex={1300}>
        <ModalBody>
          <Row>
            <Col md="12">
              <MuiThemeProvider theme={TableTheme}>
                <MUIDataTable
                  title={
                    <Typography variant="h6">
                      Movement History
                      {this.state.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                    </Typography>}
                  data={this.state.data}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn-theme-1 form-input-btn"
            color="info"
            variant="contained" size="sm" onClick={this.handleClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ViewLocationDialog;