import React, { Component } from "react";

import {
  CircularProgress,
  Typography
} from '@material-ui/core';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ButtonGroup,
  Button,
  Input,
  Col
} from "reactstrap";

import { UncontrolledCarousel } from 'reactstrap';

import MUIDataTable from "mui-datatables";
import salesOrderApi from "../../../api/SalesOrder";
import apiUtil from "../../../api/apiUtil";

import moment from "moment";

class PreviewImageDialog extends Component {
  constructor(props) {
    super(props);

    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);
    let images = props.images ? props.images : [];
    this.state = {
      modal: modal,
      isLoading: false,
      images: images,
      activeIndex: 0,
      animating: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      this.setState({
        modal: nextProps.modal
      });
    }

    if (nextProps.images !== this.state.images) {
      this.setState({
        images: nextProps.images
      })
    }
  }

  handleClose = () => {
    this.setState({
      modal: false
    }, () => {
      if (this.props.onClose) {
        this.props.onClose(true);
      }
    });
  }

  showPhotos = () => {
    const theClass = this;
    let images = this.state.images;

    if (images && images.length > 0) {
      return images.map(function (v, k) {
        if (!v.is_deleted) {
          return (<div style={{ backgroundImage: `url(${v.image_url})` }} className='col-3 img-thumbnail-container'>
            <img src={v.image_url} className="img-thumbnail" />
          </div>)
        }
      })
    }
  }

  next = () => {
    if (this.state.animating) return;
    let activeIndex = this.state.activeIndex;
    let items = this.state.images;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;

    this.setState({
      activeIndex: nextIndex
    })
  }

  previous = () => {
    if (this.state.animating) return;
    let activeIndex = this.state.activeIndex;
    let items = this.state.images;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;

    this.setState({
      activeIndex: nextIndex
    })
  }

  goToIndex = (newIndex) => {
    if (this.state.animating) return;

    this.setState({
      activeIndex: newIndex
    })
  }

  slides = () => {
    let images = this.state.images;
    if (images && images.length > 0) {
      let items = [];
       for (let i = 0; i < images.length; i++) {
        items.push({
          src: images[i].image_url,
          key: i
        });
      } 

      console.log(items);
      return  <UncontrolledCarousel items={items} />;
    }
  };

  render() {
    return (
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="xl" centered={true} zIndex={1400}>
        <ModalBody>
          <Row>
            {this.showPhotos()}
            {/* {this.slides()} */}
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button color="info" size="sm" outline={true} onClick={this.handleClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default PreviewImageDialog;