import axios from 'axios';
import moment from 'moment';

const {
  REACT_APP_WMS_API_URL
} = process.env;

const balanceEnquiryApi = {
  get: async (params) => {
    //console.log(params);
    let errorMessage = "";
    let total = 0;

    if (params.optional_filter_by && params.optional_filter_by != "query") {
      params[params.optional_filter_by] = params.optional_query;
      params.optional_filter_by = null;
      params.optional_query = null;
    }

    if (params.end_date) {
      params.start_date = (params.start_date ? moment(params.start_date).format('YYYY-MM-DD') : moment(new Date(1, 1, 1)).format('YYYY-MM-DD'));
      params.end_date = moment(params.end_date).format('YYYY-MM-DD');
    }

    const URL = REACT_APP_WMS_API_URL + "admin/api/order_details";

    let access_token = localStorage.getItem("access_token");
    let result = [];

    await axios.get(
      URL,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
      .then(res => {
        if (res.status === 200 && res.data.result.length > 0) {
          total = res.data.total;
          for (let i = 0; i < res.data.result.length; i++) {
            result[i] = res.data.result[i];
          }
        }
      }).catch(error => {
        errorMessage = error.message;
      });

    return { data: result, total: total, errorMessage: errorMessage };
  },
  
  getDetails: async (id) => {
    let errorMessage = "";
    let total = 0;

    const URL = REACT_APP_WMS_API_URL + "admin/api/order_details/" + id;

    let access_token = localStorage.getItem("access_token");
    let result;

    await axios.get(
      URL,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      })
      .then(res => {
        console.log(res);
        if (res.status === 200 && res.data && res.data.order_details) {
          result = res.data.order_details;
        }
      }).catch(error => {
        errorMessage = error.message;
      });

    return { data: result, errorMessage: errorMessage };
  },

  update: async (id, request) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/order_details/" + id;
    const access_token = localStorage.getItem("access_token");

    const result = await axios.put(
      URL,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.message;
      });

    return { data: result, errorMessage: errorMessage, status: status };
  },
  
  batchUpdateLocation: async (request) => {
    let errorMessage = "";
    let status = 200;

    const URL = REACT_APP_WMS_API_URL + "admin/api/order_details/batch_update";
    const access_token = localStorage.getItem("access_token");

    const result = await axios.post(
      URL,
      request,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      })
      .catch(error => {
        status = error.response.status;
        errorMessage = error.message;
      });

    return { data: result, errorMessage: errorMessage, status: status };
  },

  exportXLS: async (params) => {
    let errorMessage = "";
    let status = 0;

    params.download_excel = true;

    if (params.end_date) {
      params.start_date = (params.start_date ? moment(params.start_date).format('YYYY-MM-DD') : moment(new Date(1, 1, 1)).format('YYYY-MM-DD'));
      params.end_date = moment(params.end_date).format('YYYY-MM-DD');
    }

    const URL = REACT_APP_WMS_API_URL + "admin/api/order_details";

    let access_token = localStorage.getItem("access_token");

    const getResult = await axios.get(
      URL,
      {
        params: params,
        responseType: 'blob',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${access_token}`
        }
      }).catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      });

    return { data: getResult, errorMessage: errorMessage, status: status };
  }
}

export default balanceEnquiryApi;