import React, { Component } from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";

import * as XLSX from "xlsx";
import apiUtil from "../../../api/apiUtil";

class UploadPhotoDialog extends Component {
  constructor(props) {
    super(props);

    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);

    this.state = {
      modal: modal,
      file: ''
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      this.setState({
        modal: nextProps.modal
      });
    }
  }

  handleClose = () => {
    this.setState({
      modal: false
    });
    this.props.onClose();
  }

  filePathset = event => {
    event.stopPropagation();
    event.preventDefault();
    let file = event.target.files[0];
    this.setState({ file: file });
  }

  readFile = () => {
    let f = this.state.file;
    if (f === "") {
      apiUtil.toast("No file is selected yet.", "error");
      return false;
    }
    
    this.props.onImport(f, f.name);
    this.setState({ modal: false });
  }

  render() {
    return (
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="m">
        <ModalBody>
          <p><strong>Select photo:</strong></p>
          <input
            type="file"
            id="file"
            ref="fileUploader"
            accept=".jpg,.jpeg,.png"
            onChange={this.filePathset.bind(this)}
          />
          <br />
        </ModalBody>

        <ModalFooter>
          <Button color="info" size="sm" outline={true} onClick={() => this.readFile()}>
            Upload
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default UploadPhotoDialog;