import React, { Component } from "react";

import {
  Box,
  CircularProgress,
  Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import Icon from "@material-ui/core/Icon";

import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import TableTheme from '../../components/Table/Theme';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";

import Select from 'react-select';
import moment from 'moment';
import DatePicker from "react-datepicker";
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker';
import { Row, Label, InputGroup, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Card, CardBody, Col } from "reactstrap";

import apiUtil from "../../api/apiUtil";
import customerApi from "../../api/Customer";
import balanceEnquiryApi from "../../api/BalanceEnquiry";
import EditLocationDialog from "./Partial/EditLocationDialog";
import BatchEditLocationDialog from "./Partial/BatchEditLocationDialog";
import ViewLocationDialog from "./Partial/ViewLocationDialog";
import { locationData } from '../../data/misc';
import { date } from "yup/lib/locale";

class BalanceEnquiryList extends Component {
  constructor(props) {
    super(props);

    this.pageName = "balance_enquiry_list_page";
    this.customerSelectStorageSetting = "_customer_select";

    this.state = {
      displayDate: null,
      // dateRange: {
      //   startDate: null,
      //   endDate: null,
      // },
      tableState: {
        page: 0,
        count: 1,
        rowsPerPage: 25,
        sortOrder: {},
        search: ''
      },
      search: '',
      data: [],
      total: 0,
      isLoading: false,
      customerList: [],
      selectedCustomer: {
        id: 0,
        name: ''
      },
      isFilterOpen: false,
      filter: {
        customerId: null,
        text: 'Choose..',
        field: 'query',
        value: ''
      },
      editLocationModal: false,
      batchEditLocationModal: false,
      viewLocationModal: false,
      selectedItem: null,
      selectedItems: [],
      hideCustomerFilter: false
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  changePage = (page, sortOrder, rowsPerPage) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;
    tableState.rowsPerPage = rowsPerPage;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  search = (page, query) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.search = query;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  getData = async (customerId = null) => {
    if (!customerId) {
      if (!this.state.selectedCustomer.id) {
        apiUtil.toast('Select customer first!', 'error');
        return false;
      }
      customerId = this.state.selectedCustomer.id;
    }

    this.setState({ isLoading: true });

    let params = {
      enquiry: "true",
      is_balance_enquiry: "true",
      customer_id: customerId,
      optional_filter_by: this.state.filter.field,
      optional_query: this.state.filter.value,
      end_date: this.state.displayDate,
      page: this.state.tableState.page + 1,
      take: this.state.tableState.rowsPerPage,
      query: this.state.search
    };

    await balanceEnquiryApi.get(params).then(data => {

      data.data.map(function (vv, kk) {
        locationData.map(function (v, k) { if (v.value == vv.location) { vv.location = v.label; } });
      });

      this.setState({
        isLoading: false,
        data: data.data,
        total: data.total
      });
    }).catch(error => console.log(error));
  }

  getDownloadData = async (customerId = null) => {
    if (!customerId) {
      if (!this.state.selectedCustomer.id) {
        apiUtil.toast('Select customer first!', 'error');
        return false;
      }
      customerId = this.state.selectedCustomer.id;
    }

    this.setState({ isLoading: true });

    let params = {
      enquiry: "true",
      is_balance_enquiry: "true",
      customer_id: customerId,
      optional_filter_by: this.state.filter.field,
      optional_query: this.state.filter.value,
      end_date: this.state.displayDate
    };

    await balanceEnquiryApi.exportXLS(params).then(result => {
      apiUtil.parseResult(
        result,
        (response) => {
          apiUtil.saveToFile(
            response,
            'Balance Enquiry - ' + this.state.selectedCustomer.name + '.xls'
          );

          this.setState({
            isLoading: false,
          });
        },
        (error, type) => {
          this.setState({
            isLoading: false,
          });
        }
      );
    }).catch(error => console.log(error));
  }

  changeDate = date => {
    this.setState({
      displayDate: date
    }, () => {
      this.getData();
    });
  }

  getCustomer = async () => {
    await customerApi.get().then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_code,
            name: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        }, () => {
          let customerSelectFilterStr = window.localStorage.getItem(this.pageName + this.customerSelectStorageSetting);
          if (customerSelectFilterStr) {
            let customerSelectFilter = JSON.parse(customerSelectFilterStr);
            this.setState({
              selectedCustomer: {
                id: customerSelectFilter.id,
                name: customerSelectFilter.name,
                value: customerSelectFilter.value,
                label: customerSelectFilter.label
              }
            });
            this.handleCustomerChange({
              value: customerSelectFilter.id,
              name: customerSelectFilter.name,
              label: customerSelectFilter.label
            });
          }
        });
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  toggleFilter = () => {
    let isFilterOpen = this.state.isFilterOpen;

    this.setState({
      isFilterOpen: !isFilterOpen
    });
  }

  handleCustomerChange = (event) => {
    this.setState({
      selectedCustomer: {
        id: event.value,
        name: event.name,
        value: event.value,
        label: event.label
      }
    }, () => {
      window.localStorage.setItem(this.pageName + this.customerSelectStorageSetting, JSON.stringify(this.state.selectedCustomer));
    });

    this.getData(event.value);
  }

  handleFilterChange = (event) => {
    let filter = this.state.filter;
    filter.text = event.target.innerHTML;
    filter.field = event.target.value;

    this.setState({
      filter: filter
    });
  }

  handleFilterFieldChange = (event) => {
    let filter = this.state.filter;
    filter.value = event.target.value;

    this.setState({
      filter: filter
    });
  }

  handleFilterFieldKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      let filter = this.state.filter;
      filter.value = event.target.value;

      this.setState({
        filter: filter
      }, () => {
        this.search(0);
      });
    }
  }

  handleSearchFieldChange = (event) => {
    this.setState({
      search: event.target.value
    });
  }

  openEditLocationDialog = (rowIndex) => {
    var item = this.state.data[rowIndex];

    this.setState({
      selectedItem: item
    }, () => {
      this.setState({
        editLocationModal: true
      });
    });
  }

  editLocationDialog = () => {
    return <EditLocationDialog
      modal={this.state.editLocationModal}
      item={this.state.selectedItem}
      onClose={() => {
        this.setState({
          editLocationModal: false
        });
        this.getData();
      }}
    />
  }

  openBatchEditLocationDialog = (selectedRows) => {
    let selectedItems = [];
    for (let i = 0; i < selectedRows.data.length; i++) {
      var item = this.state.data[selectedRows.data[i].dataIndex];

      selectedItems.push(item);
    }

    this.setState({
      selectedItems: selectedItems
    }, () => {
      this.setState({
        batchEditLocationModal: true
      });
    });
  }

  batchEditLocationDialog = () => {
    return <BatchEditLocationDialog
      modal={this.state.batchEditLocationModal}
      items={this.state.selectedItems}
      onClose={() => {
        this.setState({
          batchEditLocationModal: false
        });
        this.getData();
      }}
    />
  }

  openViewLocationDialog = (rowIndex) => {
    var item = this.state.data[rowIndex];

    this.setState({
      selectedItem: item
    }, () => {
      this.setState({
        viewLocationModal: true
      });
    });
  }

  viewLocationDialog = () => {
    return <ViewLocationDialog
      modal={this.state.viewLocationModal}
      item={this.state.selectedItem}
      onClose={() => {
        this.setState({
          viewLocationModal: false
        });
        //this.getData();
      }}
    />
  }

  componentDidMount = () => {
    document.title = "Balance Enquiry | WMS";
    window.scrollTo(0, 0);

    let customer = JSON.parse(localStorage.getItem("customer"));

    if (customer) {
      this.setState({
        selectedCustomer: {
          id: customer.id,
          name: customer.company_name,
          label: customer.company_code
        },
        hideCustomerFilter: true
      }, () => {
        this.getData(customer.id);
      });
    } else {
      this.getCustomer();
    }
  }

  render() {
    let scope = localStorage.getItem("scope");

    const columns = [
      {
        name: "division",
        label: "Division"
      },
      {
        name: "part_no",
        label: "Part No"
      },
      {
        name: "description",
        label: "Description"
      },
      {
        name: "lot_no",
        label: "Lot No"
      },
      {
        name: "serial_no",
        label: "Serial No"
      },
      {
        name: "expiry_date",
        label: "Expiry Date",
        options: {
          customBodyRender: (value, data) => {
            return value ? moment(value).format('DD-MM-YYYY') : '';
          }
        }
      },
      {
        name: "container_receipt",
        label: "CR/PO/SO"
      },
      {
        name: "location",
        label: "Location",
        options: {
          customBodyRender: (value, data) => {
            var id = 0;
            if (data && this.state.data) {
              id = this.state.data[data.rowIndex].id;
            }
            if (apiUtil.checkAccess("BalanceEnquiryUpdateLocation")) {
              return (
                <div className="">
                  <div className="btn-group d-flex align-items-center">
                    {value}
                    <Tooltip title="Edit">
                      <button type="button"
                        className="px-0 mr-2 bg-transparent border-0"
                        onClick={() => this.openEditLocationDialog(data.rowIndex)}
                      >
                        <EditIcon />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="">
                  <div className="btn-group d-flex align-items-center">
                    {value}
                  </div>
                </div>
              );
            }
          }
        }
      },
      {
        name: "order_detail_histories",
        label: "Movement History",
        options: {
          customBodyRender: (value, data) => {
            return (<div className="btn-group d-flex align-items-center">
              <Tooltip title="Edit">
                <button type="button"
                  className="btn-theme mr-3 text-capitalize bg-info border-0"
                  onClick={() => this.openViewLocationDialog(data.rowIndex)}
                >
                  View
                </button>
              </Tooltip>
            </div>);
          }
        }
      },
      {
        name: "created_at",
        label: "Date In",
        options: {
          customBodyRender: value => moment(value).format('DD-MM-YYYY')
        }
      },
      {
        name: "balance_till_current_date",
        label: "Bal"
      },
      {
        name: "length",
        label: "Length",
        options: {
          display: scope == "customer_admin" ? "excluded" : true
        }
      },
      {
        name: "width",
        label: "Width",
        options: {
          display: scope == "customer_admin" ? "excluded" : true
        }
      },
      {
        name: "height",
        label: "Height",
        options: {
          display: scope == "customer_admin" ? "excluded" : true
        }
      },
      {
        name: "remarks",
        label: "Remarks"
      }
    ];

    const customToolbar = (selectedRows, displayData, setSelectedRows) => {
      return (
        <>
          <Button
            className="btn-theme mr-3 text-capitalize bg-info"
            onClick={() => this.openBatchEditLocationDialog(selectedRows)}
            color="primary"
            variant="contained"><span className="text-white">Edit Location</span></Button>
        </>
      );
    }

    const options = {
      selectableRows: 'none',
      rowsPerPage: this.state.tableState.rowsPerPage,
      rowsPerPageOptions: [10, 15, 25],
      serverSide: true,
      count: this.state.total,
      print: false,
      search: false,
      selectableRows: "multiple",
      selectableRowsHeader: true,
      customToolbarSelect: customToolbar,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
            break;
          case 'sort':
            this.sort(tableState.page, tableState.sortOrder);
            break;
          case 'changeRowsPerPage':
            this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
            break;
          default:
          //console.log('action not handled.');
        }
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        // data.map(function (item, index) {
        //   if (item.data[5]) { item.data[5] = moment(item.data[5]).format('DD-MM-YYYY'); }
        //   item.data[8] = moment(item.data[8]).format('DD-MM-YYYY');
        // });
        // return "\uFEFF" + buildHead(columns) + buildBody(data);
        this.getDownloadData();
        return false;
      },
      downloadOptions: { filename: 'Balance Enquiry - ' + this.state.selectedCustomer.name + '.csv', separator: ',' }
    }

    const searchInput = () => {
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          this.setState({
            search: event.target.value
          }, () => {
            this.search(0);
          });
        }
      }

      const onBlurHandler = (event) => {
        this.setState({
          search: event.target.value
        });
      }

      return <Input className="form-control form-input" name="searchInput" onKeyDown={handleKeyDown} onBlur={onBlurHandler} />
    }

    return <Box className="dashboard-page">
      <StickyPageHeader>
        <BreadCrumb />
        <h1 className="page-title">Balance Enquiry</h1>
      </StickyPageHeader>

      <Card className="list-search-box">
        <CardBody>
          <Row>
            <Col md={{ size: 6, offset: 2 }}>
              <div className={this.state.hideCustomerFilter ? "d-none" : "clearfix"}>
                <Label className="control-label">
                  Choose Company Code*
                </Label>
                <Select value={this.state.selectedCustomer}
                  className="form-input has-margin no-border"
                  options={this.state.customerList}
                  onChange={(e) => this.handleCustomerChange(e)}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
              </div>

              <div className="clearfix">
                <Label className="control-label">
                  Company Name
                </Label>
                <Input className="form-control has-margin form-input" defaultValue={this.state.selectedCustomer.name} disabled="" readOnly="readonly" />
              </div>

              <div className="clearfix">
                <Label className="control-label">
                  Search
                </Label>
                <InputGroup className="form-input-group form-input has-margin no-border">
                  <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.isFilterOpen} toggle={this.toggleFilter}>
                    <DropdownToggle caret>{this.state.filter.text}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem value="query" onClick={this.handleFilterChange}>Choose..</DropdownItem>
                      <DropdownItem value="container_receipt" onClick={this.handleFilterChange}>CR/PR/SO</DropdownItem>
                      <DropdownItem value="lot_no" onClick={this.handleFilterChange}>Lot No</DropdownItem>
                      <DropdownItem value="serial_no" onClick={this.handleFilterChange}>Serial No</DropdownItem>
                      <DropdownItem value="part_no" onClick={this.handleFilterChange}>Part No</DropdownItem>
                      <DropdownItem value="location" onClick={this.handleFilterChange}>Location</DropdownItem>
                      <DropdownItem value="description" onClick={this.handleFilterChange}>Description</DropdownItem>
                    </DropdownMenu>
                  </InputGroupButtonDropdown>
                  <Input placeholder="optional.." onBlur={this.handleFilterFieldChange} onKeyDown={this.handleFilterFieldKeyDown} />
                </InputGroup>
                {/* {searchInput()} */}
              </div>

              <div className="clearfix">
                <Label className="control-label">{' '}</Label>
                <Button
                  className="btn-theme mt-2 mt-md-0"
                  onClick={() => this.search(0)}
                  color="primary"
                  variant="contained">Search</Button>
              </div>
              {/* 
              <div className="clearfix">
                <Label className="control-label">
                  Date
                </Label>
                <DatePicker className="form-control" selected={this.state.displayDate} onChange={date => this.changeDate(date)} dateFormat="dd/MM/yyyy" />
              </div> */}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="pt-0">
        <CardBody>
          <Row>
            <Col md="12">
              <MuiThemeProvider theme={TableTheme}>
                <MUIDataTable
                  title={
                    <Typography variant="h6">
                      Balance Enquiry
                      {this.state.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                    </Typography>}
                  data={this.state.data}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {this.editLocationDialog()}
      {this.batchEditLocationDialog()}
      {this.viewLocationDialog()}
    </Box>;
  }
}

export default BalanceEnquiryList;