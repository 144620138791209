import React, { Component } from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Label,
  Button,
  FormGroup,
  Input,
  Col
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { locationData } from '../../../data/misc';
import UploadPhotoDialog from "./UploadPhotoDialog";
import apiUtil from "../../../api/apiUtil";
import api from "../../../api/api.js";

import { ReactComponent as DeleteIcon } from '../../../assets/img/icons/delete.svg';

class EditItemDialog extends Component {
  constructor(props) {
    super(props);

    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);
    let updateReadOnly = ((props.updateReadOnly === true || props.updateReadOnly === false) ? props.updateReadOnly : false);
    let selectedCustomer = props.selectedCustomer;
    this.state = {
      modal: modal,
      updateReadOnly: updateReadOnly,
      displayDate: '',
      formData: {
        index: '',
        division: '',
        part_no: '',
        description: '',
        lot_no: '',
        serial_no: '',
        quantity: '',
        width: '',
        height: '',
        length: '',
        remarks: '',
        location: '',
        expiry_date: '',
        order_images: []
      },
      selectedCustomer: selectedCustomer
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedCustomer !== this.state.selectedCustomer) {
      this.setState({
        selectedCustomer: nextProps.selectedCustomer
      });
    }
    if (nextProps.modal !== this.state.modal) {
      let formData = nextProps.data;
      console.log(formData);
      let displayDate = '';

      if (formData.expiry_date) { displayDate = new Date(formData.expiry_date); }

      let editFormData = {
        index: formData.index,
        id: formData.id,
        delete: formData.delete,
        division: formData.division,
        part_no: formData.part_no,
        description: formData.description,
        lot_no: formData.lot_no,
        serial_no: formData.serial_no,
        quantity: formData.quantity,
        width: formData.width,
        height: formData.height,
        length: formData.length,
        remarks: formData.remarks,
        location: formData.location,
        expiry_date: formData.expiry_date,
        order_images: formData.order_images
      }

      this.setState({
        formData: editFormData,
        modal: nextProps.modal,
        displayDate: displayDate
      });
    }
  }

  handleClose = () => {
    this.setState({
      modal: false
    }, () => {
      if (this.props.onUpdate) {
        this.props.onUpdate(true);
      }
    });
  }

  handleUpdate = () => {
    this.setState({
      modal: false
    }, () => {
      if (this.props.onUpdate) {
        this.props.onUpdate(this.state.formData);
      }
    });
  }

  showPhotos = () => {
    const theClass = this;
    let images = this.state.formData.order_images;

    if (images && images.length > 0) {
      return images.map(function (v, k) {
        if (!v.is_deleted) {
          return (<div style={{ backgroundImage: `url(${v.image_url})` }} className='col-2 img-thumbnail-container'>
            <img src={v.image_url} className="img-thumbnail" />
            <button title="Delete" type="button" className="btn-icon btn-delete" onClick={e => theClass.deletePhoto(k)}>
              <DeleteIcon />
            </button>
          </div>)
        }
      })
    }
  }

  deletePhoto = (idx) => {
    let formData = this.state.formData;

    if (formData.order_images) {
      if (formData.order_images[idx]['id']) {
        formData.order_images[idx]['is_deleted'] = true;
      } else {
        delete formData.order_images[idx];
        formData.order_images.splice(idx, 1);
      }
    }

    this.setState({
      formData: formData,
      isLoading: false
    });
  }

  handleInputChange = event => {
    let formState = this.state.formData;
    formState[event.target.name] = event.target.value;

    this.setState({
      formData: formState
    });
  }

  changeDate = date => {
    let formState = this.state.formData;
    formState.expiry_date = date.toLocaleDateString('en-US');

    this.setState({
      formData: formState,
      displayDate: date
    });
  }

  handleLocationChange = event => {
    let formState = this.state.formData;
    formState.location = event.value;
    this.setState({
      formData: formState
    });
  }

  setLocationDefault = (location) => {
    let value = {
      value: '',
      label: ''
    };

    for (let index in locationData) {
      if (locationData[index]['value'] === parseInt(location)) {
        value = locationData[index];
        break;
      }
    }

    return value;
  }
  
  toggleUploadPhoto = show => {
    this.setState({
      uploadModal: true
    });
  }

  uploadDialog = () => {
    if (this.state.uploadModal && (!this.props.selectedCustomer || this.props.selectedCustomer.id == 0)) {
      apiUtil.toast('Please select customer.', 'error');
      this.setState({
        uploadModal: false
      });
    } else {
      return <UploadPhotoDialog
        modal={this.state.uploadModal}
        onImport={async (data, fileName) => {
          this.setState({
            uploadModal: false
          });

          if (data) {
            if (!this.props.selectedCustomer || this.props.selectedCustomer.id == 0) {
              apiUtil.toast('Please select customer.', 'error');
            } else {
              this.setState({
                isLoading: true
              });

              let formData = new FormData();
              formData.append('picture', data);
              formData.append('picture_name', fileName);
              formData.append('folder_name', 'sales-order');

              api.uploadImage('upload', formData).then((data) => {
                if (data.data.data.result) {
                  let photoUrl = data.data.data.result.image_exact_url;

                  let formData = this.state.formData;
                  formData.order_images.push({
                    image_url: photoUrl,
                    is_deleted: false
                  });

                  this.setState({
                    formData: formData,
                    isLoading: false
                  });
                } else {
                  apiUtil.toast('Failed to upload photo.', 'error');
                }
              }).catch(error => console.log(error));

              this.setState({
                isLoading: false,
              });
            }
          }
        }}
        onClose={() => {
          this.setState({
            uploadModal: false
          });
        }}
      />
    }
  }

  render() {
    //console.log("selectedCustomer", this.state.selectedCustomer)
    return (
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="lg" className="modal-dialog-centered">
        <ModalBody>
          <Row>
            <Col xs="6">
              <FormGroup>
                <Label className="control-label">Division</Label>
                <Input name="division" className="form-input form-control" onChange={(e) => this.handleInputChange(e)} value={this.state.formData.division} />
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Part No*</Label>
                <Input name="part_no" className="form-input form-control" onChange={(e) => this.handleInputChange(e)} value={this.state.formData.part_no} />
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Description*</Label>
                <Input name="description" component="textarea" rows="5" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.description} />
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Lot No*</Label>
                <Input name="lot_no" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.lot_no} />
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Serial No*</Label>
                <Input name="serial_no" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.serial_no} />
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Expiry Date*</Label>
                <div className="form-input">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    selected={this.state.displayDate}
                    onChange={date => this.changeDate(date)}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Location*</Label>
                <Input name="location" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.location} />
                {/* <Select className="form-input no-border" options={locationData} onChange={this.handleLocationChange} defaultValue={this.setLocationDefault(this.state.formData.location)} /> */}
              </FormGroup>
            </Col>

            <Col xs="6">
              <FormGroup>
                <Label className="control-label">Quantity*</Label>
                <Input name="quantity" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.quantity} readOnly={this.state.updateReadOnly} />
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Volume*</Label>
                <Input name="length" placeholder="Length (cm)" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.length} readOnly={this.state.updateReadOnly} />
                <Input name="width" placeholder="Width (cm)" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.width} readOnly={this.state.updateReadOnly} />
                <Input name="height" placeholder="Height (cm)" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.height} readOnly={this.state.updateReadOnly} />
              </FormGroup>

              {this.state.selectedCustomer.email_alert_type_id == 4 && <FormGroup>
                <Label className="control-label">Minimum Quantity Limits*</Label>
                <Input name="minimum_quantity" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.minimum_quantity} />
              </FormGroup>}

              <FormGroup>
                <Label className="control-label">Remarks</Label>
                <Input name="remarks" component="textarea" rows="5" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.remarks} />
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-4">
            {this.showPhotos()}
          </Row>
        </ModalBody>

        <ModalFooter className="justify-content-between">
          <Button color="info" size="sm" outline={true} onClick={this.toggleUploadPhoto}>
            Upload Photo
          </Button>
          <div className="modal-footer border-0">
            <Button color="success" size="sm" outline={true} onClick={this.handleUpdate}>
              Update
            </Button>
            <Button color="danger" size="sm" outline={true} onClick={this.handleClose}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
        {this.uploadDialog()}
      </Modal>
    )
  }
}

export default EditItemDialog;