import React, { Component } from "react";

import { Box } from '@material-ui/core';
import { Container, Row, Label, Card, CardBody, Col, Input, Table, ButtonGroup } from "reactstrap";
import * as Yup from "yup";

import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import TableTheme from '../../components/Table/Theme';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import ProgressButton from "../../components/Button/ProgressButton";
import Loading from "react-fullscreen-loading";

import queryString from 'query-string';
import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import apiUtil from '../../api/apiUtil';
import customerApi from "../../api/Customer";
import salesOrderApi from "../../api/SalesOrder";
import moment from "moment";
import EditItemDialog from './Partial/EditItemDialog';
import AddItemForm from "./Partial/AddItemForm";
import { locationData } from '../../data/misc';

import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as ImageIcon } from '../../assets/img/icons/images.svg';

const SalesOrderSchema = Yup.object({
  container_receipt: Yup.string().required('Please input value'),
  hawb: Yup.string(),
  end_user_name: Yup.string(),
  total_kg: Yup.number()
    .typeError('Value must be a number'),
  //.positive('Value must be greater than zero'),
  total_package: Yup.number()
    .typeError('Value must be a number'),
  //.positive('Value must be greater than zero'),
  remarks: Yup.string()
});

class SalesOrderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      editItemData: null,
      dataId: '',
      isLoading: false,
      pageLoading: false,
      customerList: [],
      selectedCustomer: {
        id: '0',
        name: '',
        code: ''
      },
      formData: {
        handling_in_date: new Date(),
        container_receipt: '',
        hawb: '',
        end_user_id: '',
        end_user_name: '',
        total_kg: '',
        total_package: '',
        remarks: ''
      },
      order_details: [],
      readOnly: false,
      updateReadOnly: false
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
  }

  componentDidMount = () => {
    let urlQuery = queryString.parse(window.location.search);
    if (urlQuery.id !== undefined) {
      this.setState({
        dataId: urlQuery.id
      });
      document.title = "Update Sales Order | WMS";
    } else {
      document.title = "Create Sales Order | WMS";
    }

    this.setState({
      pageLoading: true,
    });

    window.scrollTo(0, 0);
    localStorage.removeItem('currentItem');

    let scope = localStorage.getItem("scope");
    if (scope != "admin") {
      this.setState({ readOnly: true }, () => this.getCustomer());
    } else {
      this.getCustomer();
    }
  }

  getCustomer = async () => {
    let scope = localStorage.getItem("scope");
    if (scope == "customer_admin") {
      let customerList = [];

      let customer = JSON.parse(localStorage.getItem("customer"));

      if (customer) {
        customerList.push({
          value: customer.id,
          label: customer.company_code,
          name: customer.company_name,
          email_alert_type_id: customer.email_alert_type_id
        });

        this.setState({
          customerList: customerList
        });

        if (this.state.dataId !== "") {
          this.getData(this.state.dataId);
        } else {
          this.setState({
            pageLoading: false,
          });
        }
      }
    }
    else {
      await customerApi.get().then(data => {
        if (Array.isArray(data.data)) {
          let customerList = [];

          data.data.forEach((val) => {
            customerList.push({
              value: val.id,
              label: val.company_code,
              name: val.company_name,
              email_alert_type_id: val.email_alert_type_id
            });
          });

          this.setState({
            customerList: customerList
          });

          if (this.state.dataId !== "") {
            this.getData(this.state.dataId);
          } else {
            this.setState({
              pageLoading: false,
            });
          }
        } else {
          apiUtil.toast('Failed to get customer data.', 'error');
        }
      }).catch(error => console.log(error));
    }
  }

  handleInputChange = event => {
    let formState = this.state.formData;
    formState[event.target.name] = event.target.value;

    this.setState({
      formData: formState
    });
  }

  handleCustomerChange = (event) => {
    this.setState({
      selectedCustomer: {
        id: event.value,
        name: event.name,
        code: event.label
      }
    });
  }

  handleSubmit = values => {
    if (this.state.order_details.length <= 0) {
      apiUtil.toast('No item in this order.', 'error');
      return false;
    }

    if (this.state.selectedCustomer.id === "") {
      apiUtil.toast('No customer is selected.', 'error');
      return false;
    }

    values.order_details = this.state.order_details.filter(function (el) {
      return el != null;
    });

    values.order_details.forEach((item, index) => {
      item.sequence = index;
      values.order_details[index] = item;
    });

    values.customer_id = this.state.selectedCustomer.id;
    values.order_branch_id = 1;
    values.handling_in_date = moment(this.state.formData.handling_in_date).format('DD-MM-YYYY');

    values.order_details.map(function (v, k) {
      if (v.expiry_date) { v.expiry_date = moment(v.expiry_date).format('DD-MM-YYYY'); }
    });

    if (this.state.dataId === "") { // create
      this.setState({
        isLoading: true
      });

      salesOrderApi.create(values).then(data => {
        this.setState({
          isLoading: false,
        });

        if (data.status === 200) {
          apiUtil.toast("Sales order created.", 'success');
          this.props.history.push('../sales-order/all');
        } else {
          apiUtil.toast(data.errorMessage, 'error');
        }
      }).catch(error =>
        apiUtil.toast(error.message, 'error')
      );
    } else { // update
      delete values.end_user_name;
      this.setState({ isLoading: true });
      salesOrderApi.update(this.state.dataId, values).then(data => {
        this.setState({
          isLoading: false,
        });

        if (data.status === 200) {
          apiUtil.toast("Sales order updated.", 'success');
          this.props.history.push('../sales-order/all');
        } else {
          apiUtil.toast(data.errorMessage, 'error');
        }
      });
    }
  }

  changeDate = date => {
    let formData = this.state.formData;
    formData.handling_in_date = date;
    this.setState({
      formData: formData
    });
  }

  addItemDialog = () => {
    // If disable add item in edit mode
    // return this.state.dataId === "" ? <AddItemForm
    //   addItem={(item) => this.addItemToOrder(item)}
    //   selectedCustomer={this.state.selectedCustomer}
    // /> : null
    if (!this.state.readOnly && !this.state.updateReadOnly) {
      return <AddItemForm
        addItem={(item) =>
          this.addItemToOrder(item)
        }
        selectedCustomer={this.state.selectedCustomer}
      />
    }
  }

  editItemDialog = () => {
    return <EditItemDialog
      modal={this.state.modal}
      updateReadOnly={this.state.updateReadOnly}
      data={this.state.editItemData}
      selectedCustomer={this.state.selectedCustomer}
      onUpdate={(data) => {
        console.log(data);
        if (typeof data === 'object' && data !== null) {
          let orderData = this.state.order_details;

          let index = data.index;
          data.volume = this.calculateVolume(data.width, data.length, data.height);

          delete data.index;

          orderData[index] = data;

          this.setState({
            modal: false,
            editItemData: null,
            order_details: orderData
          });

          localStorage.setItem('currentItem', JSON.stringify(orderData));
        } else {
          this.setState({
            modal: false,
            editItemData: null
          });
        }
      }}
    />
  }

  editItemOrder = index => {
    let orderData = this.state.order_details;
    orderData[index]['index'] = index;
    this.setState({
      modal: true,
      editItemData: orderData[index]
    });
  }

  checkItem = item => {
    if (
      isNaN(parseFloat(item.width)) ||
      isNaN(parseFloat(item.height)) ||
      isNaN(parseFloat(item.length))
    ) {
      if (item.part_no) {
        return {
          pass: false,
          message: 'Part No. ' + item.part_no + ' Width / Length / Height is not a number'
        };
      }

      return {
        pass: false,
        message: 'Width / Length / Height is not a number'
      };
    }

    if (!item.part_no) {
      return {
        pass: false,
        message: 'Part No. is required'
      };
    }

    if (!item.description) {
      return {
        pass: false,
        message: 'Description is required'
      };
    }

    if (!item.quantity) {
      return {
        pass: false,
        message: 'Quantity is required'
      };
    }

    return {
      pass: true,
      message: ''
    };
  }

  addItemToOrder = (item) => {
    let oldData = localStorage.getItem('currentItem') ? JSON.parse(localStorage.getItem('currentItem')) : this.state.order_details;
    if (Array.isArray(item)) {
      let error = false;

      item.forEach(i => {
        let checkItem = this.checkItem(i);
        if (!checkItem['pass']) {
          error = true;
          apiUtil.toast(checkItem['message'], 'error');
          return false;
        }

        i.volume = this.calculateVolume(i.width, i.length, i.height);
        locationData.map(function (v, k) {
          if (typeof i.location == "string" && i.location.toLowerCase() == v.label.toLowerCase()) {
            i.location = v.value;
          }
        });

        if (i.expiry_date) {
          let dataDate = i.expiry_date.split("/");
          i.expiry_date = dataDate[1] + "/" + dataDate[0] + "/" + dataDate[2];
        }
      });

      if (!error) {
        this.setState({
          order_details: item
        });
      }
    } else {
      let newItem = item;

      console.log(newItem);

      let checkItem = this.checkItem(newItem);
      if (!checkItem['pass']) {
        apiUtil.toast(checkItem['message'], 'error');
        return false;
      }

      newItem.volume = this.calculateVolume(newItem.width, newItem.length, newItem.height);

      this.setState({
        order_details: [...oldData, newItem]
      })

      apiUtil.toast("Item added", 'success');

      localStorage.setItem('currentItem', JSON.stringify([...oldData, newItem]));
    }
  }

  deleteItemOrder = index => {
    let orders = this.state.order_details;
    if (orders[index]['id']) {
      orders[index]['delete'] = true;
    } else {
      delete orders[index];
      orders.splice(index, 1);
    }
    this.setState({
      order_details: orders
    });
    localStorage.setItem('currentItem', JSON.stringify(orders));
  }

  calculateVolume = (width, length, height) => {
    let val = (parseFloat(width.toString()) * parseFloat(length.toString()) * parseFloat(height.toString()) / 1000000);
    let splitter = val.toString().split(".");
    return splitter.length > 1 && splitter[1].length > 6 ? parseFloat(val.toFixed(6)) : val;
  }

  calculateVolumeTotal = (volume, qty) => {
    if (volume && qty) {
      let val = (parseFloat(volume.toString()) * parseFloat(qty.toString()));
      let splitter = val.toString().split(".");
      return splitter.length > 1 && splitter[1].length > 6 ? parseFloat(val.toFixed(6)) : val;
    }

    return 0;
  }

  calculateVolumeGrandTotal = () => {
    let result = 0;
    this.state.order_details.map(function (item, index) {
      result += (parseFloat(item.volume) * parseFloat(item.quantity));
    });

    let splitter = result.toString().split(".");
    return splitter.length > 1 && splitter[1].length > 6 ? parseFloat(result.toFixed(6)) : result;
  }

  calculateQtyTotal = () => {
    let result = 0;
    this.state.order_details.map(function (item, index) {
      result += parseFloat(item.quantity);
    });

    return result;
  }

  getData = (dataId) => {
    salesOrderApi.show(dataId).then(data => {
      if (data.status === 200) {
        this.populateData(data.data.result);
      } else {
        apiUtil.toast(data.message, 'error');
      }
    });
  }

  populateData = (data) => {
    let formData = this.state.formData;
    for (const prop in formData) {
      if (data[prop] !== undefined) {
        if (prop === 'handling_in_date' && data.handling_in_date) {
          formData.handling_in_date = new Date(data.handling_in_date);
        } else {
          formData[prop] = data[prop];
        }
      }
    }

    if (data.end_user !== undefined) {
      formData.end_user_id = data.end_user.id;
      formData.end_user_name = data.end_user.end_user;
    }

    let orderDetails = data.order_details;

    if (orderDetails && orderDetails.length > 0) {
      orderDetails.sort((a, b) => a.sequence - b.sequence);
    }

    let updateReadOnly = this.state.updateReadOnly;

    if (moment(data.created_at).month() != moment().month() && moment(data.created_at).year() <= moment().year()) {
      updateReadOnly = true;
    }

    this.setState({
      pageLoading: false,
      formData: formData,
      updateReadOnly: updateReadOnly,
      order_details: orderDetails,
      selectedCustomer: {
        id: data.customer.id,
        name: data.customer.company_name,
        code: data.customer.company_code,
        email_alert_type_id: data.customer.email_alert_type_id
      }
    });
  }

  render() {
    const theClass = this;

    const columns = [
      {
        name: "id",
        label: "No.",
        options: {
          customBodyRender: (value, data) => {
            let rowNumber = data.rowIndex + 1;

            return rowNumber;
          }
        }
      },
      {
        name: "description",
        label: "Description"
      },
      {
        name: "volume",
        label: "Vol (m3)"
      },
      {
        name: "division",
        label: "Division"
      },
      {
        name: "location",
        label: "Location"
      },
      {
        name: "part_no",
        label: "Part No."
      },
      {
        name: "lot_no",
        label: "Lot No."
      },
      {
        name: "serial_no",
        label: "Serial No."
      },
      {
        name: "quantity",
        label: "Qty"
      },
      {
        name: "expiry_date",
        label: "Expiry Date",
        options: {
          customBodyRender: (value, data) => {
            return value ? moment(value).format('DD-MM-YYYY') : '';
          }
        }
      },
      {
        name: "volume",
        label: "Total Vol (m3)",
        options: {
          customBodyRender: (value, data) => {
            return theClass.calculateVolumeTotal(data.rowData[2], data.rowData[8]);
          }
        }
      },
      {
        name: "remarks",
        label: "Remarks"
      },
      {
        name: "id",
        label: "Action",
        display: theClass.state.readOnly ? false : true,
        options: {
          customBodyRender: (id, data) => {
            var itemData = this.state.order_details[data.rowIndex];

            if (theClass.state.readOnly) {
              return "";
            } else if (theClass.state.updateReadOnly) {
              if (itemData.order_images && itemData.order_images.length > 0) {
                return <ButtonGroup>
                  <EditIcon className="fix-icon-color btn-icon" title="Edit" onClick={e => theClass.editItemOrder(data.rowIndex)} />
                  <ImageIcon />
                </ButtonGroup>;
              } else {
                return <ButtonGroup>
                  <EditIcon className="fix-icon-color btn-icon" title="Edit" onClick={e => theClass.editItemOrder(data.rowIndex)} />
                </ButtonGroup>;
              }
            }

            if (itemData.order_images && itemData.order_images.length > 0) {
              return (<ButtonGroup>
                <EditIcon className="fix-icon-color btn-icon" title="Edit" onClick={e => theClass.editItemOrder(data.rowIndex)} />
                <button title="Delete" type="button" className="btn-icon" onClick={e => theClass.deleteItemOrder(data.rowIndex)}>
                  <DeleteIcon />
                </button>
                <button title="Have Images" type="button" className="btn-icon" onClick={e => theClass.editItemOrder(data.rowIndex)}>
                  <ImageIcon />
                </button>
              </ButtonGroup>);
            } else {
              return (<ButtonGroup>
                <EditIcon className="fix-icon-color btn-icon" title="Edit" onClick={e => theClass.editItemOrder(data.rowIndex)} />
                <button title="Delete" type="button" className="btn-icon" onClick={e => theClass.deleteItemOrder(data.rowIndex)}>
                  <DeleteIcon />
                </button>
              </ButtonGroup>);
            }
          }
        }
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: 1000,
      rowsPerPageOptions: [1000],
      serverSide: false,
      sort: false,
      download: false,
      print: false,
      search: false,
      viewColumns: false,
      filter: false,
      pagination: false
    }

    return (
      <Box className="dashboard-page">
        <Loading loading={this.state.pageLoading} background="#f5f5f5" loaderColor="#e89cae" />
        <StickyPageHeader>
          <BreadCrumb />
          <h1 className="page-title">{this.state.dataId !== "" ? this.state.readOnly ? "View Sales Order" : "Update Sales Order" : "Create Sales Order"}</h1>
        </StickyPageHeader>

        <Row>
          <Formik
            enableReinitialize={true}
            initialValues={{
              container_receipt: this.state.formData.container_receipt,
              hawb: this.state.formData.hawb,
              end_user_name: this.state.formData.end_user_name,
              total_kg: this.state.formData.total_kg ?? '',
              total_package: this.state.formData.total_package ?? '',
              remarks: this.state.formData.remarks
            }}
            validationSchema={SalesOrderSchema}
            onSubmit={this.handleSubmit}>
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
              touched,
              validateForm
            }) => (
              <Form>
                <div className={this.state.readOnly ? "overlay" : ""}>
                  <Col md={{ size: 12 }}>
                    <Card className="card pb-0 mb-1 mt-2">
                      <CardBody>
                        <Container>
                          <Row>
                            <Col md="12">
                              <div className="mb-4">
                                <Label className="control-label">
                                  Choose Company Code*
                                </Label>
                                <Select value={{ label: this.state.selectedCustomer.code, value: this.state.selectedCustomer.id }} className="form-input has-margin no-border" options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
                              </div>

                              <div className="mb-4">
                                <Label className="control-label">
                                  Company Name
                                </Label>
                                <Input className="form-control has-margin form-input" defaultValue={this.state.selectedCustomer.name} disabled="" readOnly="readonly" />
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </CardBody>
                    </Card>

                    <Card>
                      <CardBody>
                        <Container>
                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Date
                              </Label>
                              <div className="form-input">
                                <DatePicker
                                  className="form-control"
                                  dateFormat="dd/MM/yyyy"
                                  selected={this.state.formData.handling_in_date}
                                  onChange={date => this.changeDate(date)}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                CR/PO/SO*
                              </Label>
                              <Field className="form-control form-input" name="container_receipt" value={this.state.formData.container_receipt || ''} onChange={this.handleInputChange} />
                              {
                                errors.container_receipt && touched.container_receipt ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.container_receipt}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                HAWB
                              </Label>
                              <Field className="form-control form-input" name="hawb" value={this.state.formData.hawb || ''} onChange={this.handleInputChange} />
                              {
                                errors.hawb && touched.hawb ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.hawb}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                End User
                              </Label>
                              <Field className="form-control form-input" name="end_user_name" value={this.state.formData.end_user_name || ''} onChange={this.handleInputChange} />
                              {
                                errors.end_user_name && touched.end_user_name ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.end_user_name}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Total Weight (kg)
                              </Label>
                              <Field className="form-control form-input" type="number" name="total_kg" value={this.state.formData.total_kg || ''} onChange={this.handleInputChange} placeholder="kg" />
                              {
                                errors.total_kg && touched.total_kg ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.total_kg}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Total Packages
                              </Label>
                              <Field className="form-control form-input" name="total_package" value={this.state.formData.total_package || ''} onChange={this.handleInputChange} placeholder="quantity" />
                              {
                                errors.total_package && touched.total_package ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.total_package}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Remarks
                              </Label>
                              <Field className="form-control form-input" name="remarks" component="textarea" value={this.state.formData.remarks || ''} onChange={this.handleInputChange} rows="5" />
                              {
                                errors.remarks && touched.remarks ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.remarks}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>
                </div>
                <div>
                  <Col md={{ size: 12 }}>
                    <Card className="mt-2">
                      <CardBody>
                        <Col xs="12">
                          <h4 className="font-weight-bold mb-4">Items in this Order</h4>
                        </Col>
                        <Col md="12">
                          <MuiThemeProvider theme={TableTheme}>
                            <MUIDataTable
                              data={this.state.order_details.filter(item => item.delete !== true)}
                              columns={columns}
                              options={options}
                            />
                          </MuiThemeProvider>
                        </Col>
                        <div className="table-responsive">
                          <Table className="mb-5">
                            {/* <thead>
                              <tr>
                                <th>No.</th>
                                <th>Description</th>
                                <th>vol(m<sup>3</sup>)</th>
                                <th>Division</th>
                                <th>Location</th>
                                <th>Part No.</th>
                                <th>Lot No.</th>
                                <th>Serial No.</th>
                                <th>Qty</th>
                                <th>Expiry Date</th>
                                <th>Total Vol(m<sup>3</sup>)</th>
                                <th>Remarks</th>
                                <th className={this.state.readOnly ? "d-none" : ""}>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {this.state.order_details.map(function (item, index) {
                                if (item.delete !== true) {
                                  return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.description}</td>
                                    <td>{item.volume}</td>
                                    <td>{item.division}</td>
                                    <td>{item.location}</td>
                                    <td>{item.part_no}</td>
                                    <td>{item.lot_no}</td>
                                    <td>{item.serial_no}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.expiry_date ? moment(item.expiry_date).format('DD-MM-YYYY') : ""}</td>
                                    <td>{theClass.calculateVolumeTotal(item.volume, item.quantity)}</td>
                                    <td>{item.remarks}</td>
                                    <td className={theClass.state.readOnly ? "d-none" : ""}>
                                      <ButtonGroup>
                                        <EditIcon className="fix-icon-color btn-icon" title="Edit" onClick={e => theClass.editItemOrder(index)} />
                                        <button title="Delete" type="button" className="btn-icon" onClick={e => theClass.deleteItemOrder(index)}>
                                          <DeleteIcon />
                                        </button>
                                      </ButtonGroup>
                                    </td>
                                  </tr>
                                } else return "";
                              })}
                            </tbody> */}
                            <tfoot>
                              <tr>
                                <th colSpan='7' style={{ textAlign: 'right' }}>Total Qty</th>
                                <th>{theClass.calculateQtyTotal()}</th>
                                <th style={{ textAlign: 'right' }}>Grand Total Vol (m<sup>3</sup>)</th>
                                <th colSpan='2'>{theClass.calculateVolumeGrandTotal()}</th>
                              </tr>
                            </tfoot>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>

                    <Card className="mt-4">
                      <CardBody>
                        <Container>
                          {this.addItemDialog()}
                        </Container>
                      </CardBody>
                    </Card>
                    <Card className="mt-4">
                      <CardBody>
                        <Container>
                          {!this.state.readOnly && <ProgressButton
                            type={'submit'}
                            color={'secondary'}
                            isLoading={this.state.isLoading}>
                            <Box pl={2} pr={2}>Submit</Box>
                          </ProgressButton>
                          }
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>
                </div>
              </Form>
            )}
          </Formik>
        </Row>
        {this.editItemDialog()}
      </Box>
    );
  }
}

export default SalesOrderForm;